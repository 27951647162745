import React, { useRef, useState } from 'react'
import axios from 'axios'
import CSS from 'csstype'
import InputMask from 'react-input-mask'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import { Button, Grid, ViewMore } from '@components'
import dataLayerGlobal from '@utils/dataLayerGlobal'
import * as st from '@assets/styl/FAQ.module.styl'
import cn from 'classnames'

type Question = {
  title: string
  content: string
}

type FAQProps = {
  pageHelp?: boolean
}

const QuestionLi = ({ title, content: text }: Question) => {
  const defStyle: CSS.Properties = {}
  const [style, setStyle] = useState(defStyle)
  const content = useRef<HTMLParagraphElement>()
  const expand = () => {
    const height = content.current.offsetHeight
    setStyle({ maxHeight: `${height}px` })
    setTimeout(() => setStyle({ maxHeight: 'none' }), 200)
  }
  const contract = () => {
    const height = content.current.offsetHeight
    setStyle({ maxHeight: `${height}px` })
    setTimeout(() => setStyle({ maxHeight: 0 }), 50)
  }
  const onClick = () => {
    if (!style.maxHeight) expand()
    else contract()
  }

  const videos = [
    'https://www.youtube.com/embed/RcplSQ3hB3E?rel=0',
    'https://www.youtube.com/embed/mtWDMGTSmfU?rel=0',
    'https://www.youtube.com/embed/5NdlPOuFrpg?rel=0',
    'https://www.youtube.com/embed/yoqnpR137Es?rel=0',
    'https://www.youtube.com/embed/NOmtv9OrmLw?rel=0',
    'https://www.youtube.com/embed/Vv4HuL-zAsg?rel=0',
  ]

  return (
    <li>
      <h4>
        <span>
          <span onClick={onClick} className="expand-view-more">
            {title}
          </span>
        </span>
        <button
          className={cn(
            style.maxHeight ? 'icon-minus' : 'icon-plus',
            'expand-view-more'
          )}
          onClick={onClick}
        ></button>
      </h4>
      <div className={`${st.content} ${!text ? st.iframe : ''}`} style={style}>
        {!text && title === 'Como fazer o cadastro no App WeCharge' && (
          <>
            <iframe
              title="YouTube video 1"
              src={videos[0]}
              allowFullScreen
            ></iframe>
          </>
        )}
        {!text && title === 'Mais dúvidas - Tutoriais' && (
          <>
            <iframe
              title="YouTube video 2"
              src={videos[1]}
              allowFullScreen
            ></iframe>
            <iframe
              title="YouTube video 3"
              src={videos[2]}
              allowFullScreen
            ></iframe>
            <iframe
              title="YouTube video 4"
              src={videos[3]}
              allowFullScreen
            ></iframe>
            <iframe
              title="YouTube video 5"
              src={videos[4]}
              allowFullScreen
            ></iframe>
            <iframe
              title="YouTube video 6"
              src={videos[5]}
              allowFullScreen
            ></iframe>
          </>
        )}
        <p
          ref={content}
          dangerouslySetInnerHTML={text ? { __html: text } : undefined}
        ></p>
      </div>
    </li>
  )
}

export default ({ pageHelp }: FAQProps) => {
  const dataLayer = dataLayerGlobal()
  const query = useStaticQuery(graphql`
    query {
      allConfigurations {
        nodes {
          whatsapp
        }
      }
      allQuestions {
        nodes {
          title
          content
        }
      }
    }
  `)
  const config = query.allConfigurations.nodes[0]
  const questions = query.allQuestions.nodes

  const formatDate = () => {
    const date = new Date()
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  const [data, setData] = useState({
    Email: '',
    nome: '',
    Telefone: ''.replace(/\D/g, ''),
    Finalidade_de_contato: '',
    Politica_de_privacidade: false,
    Communication: false,
    Data_criacao: formatDate(),
    Fluxo_midia: 'Formulário de Contato',
  })
  const [buttonLabel, setButtonLabel] = useState('Enviar')
  const onChange = ({ target: { id, value } }) => {
    setData({ ...data, [id]: value })
  }
  const onChangeCheckbox = ({ target: { id, checked } }) => {
    setData({ ...data, [id]: checked })
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setButtonLabel('Enviando...')

    const sanitizedData = {
      ...data,
      Telefone: data.Telefone.replace(/\D/g, ''),
    }

    fetch('https://admin.wecharge.com.br/api/salesforce', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sanitizedData),
    }).then((response) => {
      setButtonLabel('Enviar')
      if (response.status === 201) {
        navigate('/sucesso/')
        dataLayer.push({
          event: 'GA - Eventos',
          eventCategory: 'Conversão',
          eventLabel: 'Formulário de contato',
        })
      } else {
        navigate('/404/')
      }
    })
  }

  const onWhatsappClick = () => {
    dataLayer.push({
      event: 'GA - Eventos',
      eventCategory: 'Click WhatsApp',
      eventLabel: 'WhatsApp',
    })
  }

  return (
    <section className={pageHelp ? st.noPadding : st.core}>
      <Grid>
        <h3>Perguntas frequentes</h3>
        <p>
          Selecionamos as principais dúvidas referentes a tecnologia da
          Wecharge.
        </p>
        <ViewMore title="perguntas frequentes">
          <ul className={st.questions}>
            {questions.map((question, key) => (
              <QuestionLi {...question} key={key} />
            ))}
          </ul>
        </ViewMore>
        <div className={st.formWrapper} id="contato">
          <div>
            <div className={st.text}>
              <h3>Faça parte desta inovação.</h3>
              <p>
                Precisando de carregadores elétricos?
                <br />
                Entre em contato e solicite mais informações.
              </p>
              <Button
                to={`https://whatsa.me/5508002910045/?t=Ol%C3%A1!%20Vim%20pelo%20site%20da%20WeCharge%20e%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es`}
                external
                color="green"
                className={st.wppBtn}
                onClick={onWhatsappClick}
              >
                <span className="icon-whatsapp"></span>
                Chamar no WhatsApp
              </Button>
            </div>
            <form onSubmit={onSubmit}>
              <div className={st.formGroup}>
                <label htmlFor="nome">Nome</label>
                <input
                  type="text"
                  id="nome"
                  required
                  value={data.nome}
                  onChange={onChange}
                />
              </div>
              <div className={st.formGroup}>
                <label htmlFor="Email">E-mail</label>
                <input
                  type="email"
                  id="Email"
                  required
                  value={data.Email}
                  onChange={onChange}
                />
              </div>

              <div className={st.formGroup}>
                <label htmlFor="Finalidade_de_contato">
                  Finalidade do contato
                </label>
                <select
                  id="Finalidade_de_contato"
                  required
                  value={data.Finalidade_de_contato}
                  onChange={onChange}
                >
                  <option hidden disabled value="">
                    Finalidade do contato
                  </option>
                  <option>Carregador para o meu estabelecimento</option>
                  <option>Carregador para minha garagem</option>
                  <option>Carregador para o meu condomínio</option>
                  <option>Carregador para minha frota</option>
                  <option>Outra</option>
                </select>
              </div>
              <div className={st.formGroup}>
                <label htmlFor="Telefone">Telefone</label>
                <InputMask
                  mask={
                    data.Telefone.length > 14
                      ? '(99) 99999-9999'
                      : '(99) 9999-99999'
                  }
                  maskChar={null}
                  type="tel"
                  id="Telefone"
                  required
                  pattern=".{14,15}"
                  value={data.Telefone}
                  onChange={onChange}
                />
              </div>
              <div className={cn(st.formGroup, st.wide, st.checkboxWrapper)}>
                <input
                  type="checkbox"
                  id="Politica_de_privacidade"
                  required
                  checked={data.Politica_de_privacidade}
                  onChange={onChangeCheckbox}
                />
                <label htmlFor="Politica_de_privacidade">
                  Ao me cadastrar estou de acordo com os{' '}
                  <Link to="/termos-de-uso/" target="_blank">
                    termos de uso
                  </Link>{' '}
                  e{' '}
                  <Link to="/politica-de-privacidade/" target="_blank">
                    política de privacidade
                  </Link>
                  .
                </label>
              </div>
              <div className={cn(st.formGroup, st.wide, st.checkboxWrapper2)}>
                <input
                  type="checkbox"
                  id="Communication"
                  required
                  checked={data.Communication}
                  onChange={onChangeCheckbox}
                />
                <label htmlFor="Communication">
                  Autorizo o Grupo Dimas a armazenar meus dados pessoais para
                  enviar campanhas de marketing e informações sobre a empresa
                  nos canais: Telefone, Email e SMS.
                </label>
              </div>
              <div className={st.btnWrapper}>
                <Button className={st.btn}>{buttonLabel}</Button>
              </div>
            </form>
          </div>
        </div>
      </Grid>
    </section>
  )
}
