import React, { useEffect, useState } from 'react'
import requestData from '@api'
import { useStaticQuery, graphql } from 'gatsby'
import { Button, Grid } from '@components'
import useWindowSize from '@utils/useWindowSize'
import dataLayerGlobal from '@utils/dataLayerGlobal'
import * as st from '@assets/styl/AboutSection.module.styl'
import whatImg from '@assets/img/carregador.png'
import appImg from '@assets/img/aplicativo.png'
import appImgMobile from '@assets/img/aplicativo.png'
import goalImg from '@assets/img/mercado-automotivo.png'
import customImg from '@assets/img/totem.png'
import whatImgMobile from '@assets/img/carregador-mobile.jpg'
import goalImgMobile from '@assets/img/mercado-automotivo-mobile.jpg'
import customImgMobile from '@assets/img/totem-mobile.jpg'
import _ from 'underscore'
import { LazyImageFull, ImageState } from 'react-lazy-images'

export default () => {
  const query = useStaticQuery(graphql`
    query {
      allNumbers {
        nodes {
          title
          number
        }
      }
    }
  `)
  const [numbers, setNumbers] = useState<
    Array<{ title: string; number: string }>
  >(query.allNumbers.nodes)
  useEffect(() => {
    requestData('get', 'numbers?max=9999')
      .then(({ result }) =>
        result
          .sort(({ id: a }, { id: b }) => (a == b ? 0 : b > a ? -1 : 1))
          .forEach(({ title, number }, index) => {
            numbers[index].title = title
            numbers[index].number = number
          })
      )
      .then((_) => setNumbers([...numbers]))
  }, [])
  const vs = useWindowSize()
  const dataLayer = dataLayerGlobal()

  const onIosClick = () => {
    dataLayer.push({
      event: 'GA - Eventos',
      eventCategory: 'Click Download',
      eventLabel: 'IOS',
    })
  }

  const onAndroidClick = () => {
    dataLayer.push({
      event: 'GA - Eventos',
      eventCategory: 'Click Download',
      eventLabel: 'Android',
    })
  }

  return (
    <section id="wecharge">
      <Grid>
        <div className={st.app}>
          <LazyImageFull
            src={!vs.width || vs.width < 750 ? appImgMobile : appImg}
          >
            {({ imageState, ref, imageProps: { src } }) => (
              <div ref={ref}>
                <img src={imageState === ImageState.LoadSuccess ? src : ''} />
              </div>
            )}
          </LazyImageFull>
          <div>
            <h4>
              App <b>wecharge</b>
            </h4>
            <h3>Praticidade e controle</h3>
            <p>
              Carregue seu carro <b>elétrico</b> ou <b>híbrido</b> com um toque,
              independente da marca ou modelo de seu veículo.
            </p>
            <ul>
              <li>
                <span className="icon-list-item"></span>
                <span>
                  Encontre pontos de carregamento e saiba seu horário de
                  funcionamento
                </span>
              </li>
              <li>
                <span className="icon-list-item"></span>
                <span>Confira se o eletroposto está ocupado ou disponível</span>
              </li>
              <li>
                <span className="icon-list-item"></span>
                <span>Conheça os plugs disponíveis em cada eletroposto</span>
              </li>
              {/* <li><span className="icon-list-item"></span><span>Faça sua reserva</span></li> */}
              <li>
                <span className="icon-list-item"></span>
                <span>Ative o carregamento e pague diretamente pelo APP</span>
              </li>
              <li>
                <span className="icon-list-item"></span>
                <span>Acompanhe sua recarga remotamente</span>
              </li>
              <li>
                <span className="icon-list-item"></span>
                <span>
                  Consulte seu histórico e <b>muito mais!</b>
                </span>
              </li>
            </ul>
            <p>Baixe agora o App Wecharge</p>
            <div className={st.links}>
              <a
                href="https://apps.apple.com/br/app/wecharge/id1595933153"
                target="_blank"
                className={st.ios}
                onClick={onIosClick}
              ></a>
              <a
                href="https://play.google.com/store/apps/details?id=br.com.voltbras.wecharge&hl=pt"
                target="_blank"
                className={st.android}
                onClick={onAndroidClick}
              ></a>
            </div>
          </div>
        </div>

        <div className={st.what}>
          {(!vs.width || vs.width < 750) && (
            <LazyImageFull src={whatImgMobile}>
              {({ imageState, ref, imageProps: { src } }) => (
                <div ref={ref}>
                  <img src={imageState === ImageState.LoadSuccess ? src : ''} />
                </div>
              )}
            </LazyImageFull>
          )}
          <div>
            <h3>
              Somos uma empresa de mobilidade elétrica e rede de eletropostos
              inteligentes.
            </h3>
            <p>
              Uma rede conectada para facilitar a vida de usuários de carros
              elétricos e híbridos.
            </p>
            <Button to="solucoes" scroll className={st.btn}>
              Conheça nossas soluções
            </Button>
          </div>
          {vs.width >= 750 && (
            <LazyImageFull src={whatImg}>
              {({ imageState, ref, imageProps: { src } }) => (
                <div ref={ref}>
                  <img src={imageState === ImageState.LoadSuccess ? src : ''} />
                </div>
              )}
            </LazyImageFull>
          )}
        </div>

        <div className={st.goal}>
          <div>
            <LazyImageFull
              src={!vs.width || vs.width < 750 ? goalImgMobile : goalImg}
            >
              {({ imageState, ref, imageProps: { src } }) => (
                <div ref={ref}>
                  <img src={imageState === ImageState.LoadSuccess ? src : ''} />
                </div>
              )}
            </LazyImageFull>
          </div>
          <div>
            <h3>
              Nosso objetivo é disseminar a eletrificação no mercado automotivo.
            </h3>
            <p>
              Oferecendo acesso a uma infraestrutura de carregamento de veículos
              elétricos.
            </p>
            <Button to="/pontos-de-recarga/" className={st.btn}>
              Encontre nossos pontos de recarga
            </Button>
          </div>
        </div>

        <div className={st.custom}>
          {(!vs.width || vs.width < 750) && (
            <LazyImageFull src={customImgMobile}>
              {({ imageState, ref, imageProps: { src } }) => (
                <div ref={ref}>
                  <img src={imageState === ImageState.LoadSuccess ? src : ''} />
                </div>
              )}
            </LazyImageFull>
          )}
          <div>
            <h3>Desenvolvemos soluções personalizadas.</h3>
            <p>
              Seguindo os padrões e as tendências dos mercados
              <br /> de automóveis e construção civil.
            </p>
            <Button to="solucoes" scroll className={st.btn}>
              Conheça nossas soluções
            </Button>
          </div>
          {vs.width >= 750 && (
            <LazyImageFull src={customImg}>
              {({ imageState, ref, imageProps: { src } }) => (
                <div ref={ref}>
                  <img src={imageState === ImageState.LoadSuccess ? src : ''} />
                </div>
              )}
            </LazyImageFull>
          )}
        </div>

        <div className={st.numbers}>
          <h3>Números Wecharge</h3>
          <ul>
            <li>
              <div className="icon-users">
                {_.range(1, 8).map((path, key) => (
                  <span key={key} className={`path${path}`}></span>
                ))}
              </div>
              <b>{numbers[4].number}</b>
              {numbers[4].title}
            </li>
            <li>
              <div className="icon-cities">
                {_.range(1, 6).map((path, key) => (
                  <span key={key} className={`path${path}`}></span>
                ))}
              </div>
              {console.log(numbers)}
              <b>{numbers[9].number}</b>
              {numbers[9].title}
            </li>
            <li>
              <div className="icon-stations">
                {_.range(1, 4).map((path, key) => (
                  <span key={key} className={`path${path}`}></span>
                ))}
              </div>
              <b>{numbers[7].number}</b>
              {numbers[7].title}
            </li>
            <li>
              <div className="icon-recharges">
                {_.range(1, 10).map((path, key) => (
                  <span key={key} className={`path${path}`}></span>
                ))}
              </div>
              <b>{numbers[8].number}</b>
              {numbers[8].title}
            </li>
            <li>
              <div className="icon-energy">
                {_.range(1, 16).map((path, key) => (
                  <span key={key} className={`path${path}`}></span>
                ))}
              </div>
              <b>{numbers[10].number}</b>
              {numbers[10].title}
            </li>
          </ul>
        </div>
      </Grid>
    </section>
  )
}
